<template>
  <div class="uspBannerContainer">
    <div v-if="!isProductPage">
      <div class="uspBanner">
        <div
          v-for="banner in uspData"
          :key="banner.title"
          style="display: flex;"
        >
          <router-link v-if="banner.target" :to="banner.target" class="bannerItem">
            <img
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            >
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </router-link>
          <div v-else class="bannerItem">
            <img
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            >
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <Carousel
        :autoplay="true"
        :loop="true"
        :per-page="1"
        :pagination-enabled="true"
        :autoplay-timeout="6000"
        class="carousel"
      >
        <Slide
          v-for="banner in uspData"
          :key="`${banner.title}-slide`"
          style="display: flex;"
        >
          <router-link v-if="banner.target" :to="banner.target" class="bannerItem">
            <img
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            >
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </router-link>
          <div v-else class="bannerItem">
            <img
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            >
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
    <div v-else>
      <div class="uspBannerProductPage">
        <div
          v-for="banner in uspProductPageData"
          :key="banner.title"
          style="display: flex;"
        >
          <router-link v-if="banner.target" :to="banner.target" class="bannerItem">
            <img
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            >
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </router-link>
          <div v-else class="bannerItem">
            <img
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            >
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <Carousel
        :autoplay="true"
        :loop="true"
        :per-page="1"
        :pagination-enabled="true"
        :autoplay-timeout="6000"
        class="carouselProductPage"
      >
        <Slide
          v-for="banner in uspProductPageData"
          :key="`${banner.title}-slide`"
          class="bannerItem"
        >
          <router-link v-if="banner.target" :to="banner.target" class="bannerItem">
            <v-lazy-image
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            />
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </router-link>
          <div v-else class="bannerItem">
            <v-lazy-image
              :src="getImgUrl(banner.icon)"
              :alt="banner.title"
              class="uspImage"
            />
            <div class="uspText">
              <p class="title">{{ banner.title }}</p>
              <p class="description">{{ banner.description }}</p>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'USPBanner',
  components: {
    Carousel,
    Slide
  },
  props: {
    isProductPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      uspData: [
        {
          title: 'Over 12 000 Products',
          description: 'UK\'s nº1 vegan supermarket',
          icon: 'uspRosette.svg'
        },
        {
          title: 'Free Chilled Delivery',
          description: 'On orders over £60',
          icon: 'uspVan.svg',
          target: '/delivery'
        },
        {
          title: 'Chilled and Frozen',
          description: 'Chef quality foods',
          icon: 'uspChilled.svg'
        },
        {
          title: 'Discover New Foods',
          description: 'Buy directly from top vegan brands',
          icon: 'uspStar.svg'
        }
      ],
      uspProductPageData: [
        {
          title: 'Free Chilled and Frozen Delivery (UK)',
          description: 'On orders over £60',
          icon: 'uspVan.svg',
          target: '/delivery'
        },
        {
          title: 'Simple and fast shopping',
          description: 'Checkout just with a few clicks',
          icon: 'uspRosette.svg'
        },
        {
          title: 'Subscribe to this product',
          description: 'Available at Checkout',
          icon: 'uspStar-old.svg'
        }
      ],
      carouselOptions: {
        perPage: 1,
        paginationEnabled: true,
        autoplayTimeout: 4000
      }
    }
  },
  methods: {
    getImgUrl (imageSrc) {
      return require('../assets/' + imageSrc)
    }
  }
}
</script>

<style scoped lang="stylus">
.uspBannerContainer {
  max-width: 1040px;
  border-radius:10px
  margin: 0 0 25px 0;

  .carousel {
    display: none;

    @media screen and (max-width: 440px) {
      display: flex;
    }
  }

  .uspBanner {
    // display: flex;
    // flex-direction: row;
    display: grid;
    grid-template-columns: repeat(4, minmax(175px, 1fr))
    // padding: 0 10px;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, minmax(175px, 1fr))
    }
    @media screen and (max-width: 440px) {
      display: none !important;
    }

  }

  .carouselProductPage {
    display: none;

    @media screen and (max-width: 550px) {
      display: flex;
    }
  }

  .uspBannerProductPage {
    display: grid;
    grid-template-rows: repeat(2, minmax(100px, 1fr))
    margin-left: 50px;

    @media screen and (max-width: 960px) {
      grid-template-rows: repeat(1, minmax(100px, 1fr))
      grid-template-columns: repeat(2, minmax(175px, 1fr))
      margin-left: 0;
    }
    @media screen and (max-width: 550px) {
      display: none !important;
    }

  }
  .bannerItem {

    width: 100%;
    // min-width: 25%
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 15px 0px;

    .uspText {
      padding: 0 0px 0 10px;
      text-align: left;

      .title {
        font-weight: 800;
      }

      p {
        margin: 5px 0;
      }
    }

    .uspImage {
      width: 50px;
      height: 50px;
    }
  }
  * >>> .VueCarousel-dot {
    margin-top: 0 !important;
  }
}
</style>
